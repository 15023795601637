import React, { Component } from "react";
import './ResultDescription.scss';

class ResultDescription extends Component {

    render() {
        return (
            <div className="result-desc-wrap">
                <h1><span>{this.props.value}{this.props.percentage}</span> {this.props.level}</h1>
                <p dangerouslySetInnerHTML={{__html:this.props.text.replace(/(\r\n|\n|\r)/gm, "<br>")}}></p>
            </div>
        );
    }
}

export default ResultDescription;