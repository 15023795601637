import React, { Component } from "react";
import './progressbar.scss';
import logo from '../../images/Heart.svg';
import classnames from 'classnames';

class ProgressBar extends Component {

    renderSteps(step) {
        const value = this.props.value;
        return(
            <div>{value}</div>
        )
    }
    
    digits() {
        let digits = [];
        for (let i = 0; i < 13; i++) {
            digits.push(<span key={i}>{i}</span>)
        }
        return digits;
    }

    render(props) {
        var width = this.props.value ? ((this.props.value/12) * 100).toFixed(2) : ((0/12) * 100).toFixed(2);
        if (width > 100) {
            width = 100 + '%';
        } else {
            width = width + '%';
        }
        var borderRadius = "";

        if(this.props.value >= 12) {
            borderRadius = "20px 20px 20px 20px";
        } else {
            borderRadius = "20px 0 0 20px";
        }

        return (
            <div className="progress-bar-wrap">
                <div className="progress-bar-header">
                    <img className="logo" src={logo} alt="{this.props.title}" /><h2>{this.props.title}</h2>
                </div>
                <span className="digits heart">
                 {this.digits()}
                </span>
                <div className="progress-bar">
                <div className={classnames('progress-bar-fill', this.props.valuetitle)} style={{
                    width: width, borderRadius: borderRadius}}></div>
                    <div className="stepline stepline__1"></div>
                    <div className="stepline stepline__2"></div>
                    <div className="stepline stepline__3"></div>
                </div>
                <div className={classnames('steps', this.props.valuetitle)}>
                    <span className="matala">{this.props.level && this.props.levels[0]}</span>
                    <span className="keskitaso">{this.props.level && this.props.levels[1]}</span>
                    <span className="kohonnut">{this.props.level && this.props.levels[2]}</span>
                    <span className="korkea">{this.props.level && this.props.levels[3]}</span>
                </div>

            </div>
        );
    }
}

export default ProgressBar; 