import React, { Component } from 'react';
import axios from 'axios';

import Header from './components/header/header';
import Form from './components/form/form';
import Results from './components/results/results';
import scrollToComponent from 'react-scroll-to-component';

import logo from './images/Company@2x.png'

import "./assets/styles/main.scss";

class App extends Component {

  constructor(props) {  
    super(props)
    this.state = {
      closed: true,
      value: "",
      valuediab: "",
      valid: {
        value: 0,
        valuediab: 0,
        electedOption: "0"
      },
      selectedOption: "0",
      serverData: {
        submit_input: "",
      },
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  handleChange(e) {
    if (e.target.type !== 'radio') {
      this.setState({
        [e.target.name]: e.target.checkValidity() ? Number(e.target.value.replace(/,/g, '.')) : false
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  }

  handleClick(e) {
    if (false !== this.state.value && false !== this.state.valuediab) {
      this.setState({
        closed: false,
        valid: {
          value: this.state.value,
          valuediab: this.state.valuediab,
          selectedOption: this.state.selectedOption
        }
      });
      
      setTimeout(() => {
        scrollToComponent(this.results, {
          offset: 50,
          align: 'middle',
          duration: 1000,
          ease:'inOutQuad'
        });
      }, 400);
    }
  }

  handleSubmit(e) {
    e.preventDefault()
  }
  
  componentDidMount() {

    if(document.documentElement.lang === 'fi') {
      axios.get('https://hertta-uusi.wp.aucor.fi/wp-json/acf/v3/options/naytelaskuri/fi/')
      .then(response => {
        let data = response.data.acf.fi;
        data.radio = ['Kyllä', 'Ei'];
        data.levels = ['Matala', 'Keskitaso', 'Kohonnut', 'Korkea'];
        data.heartTitle = "Sydänkohtausriski";
        data.diabTitle = "Diabetesriski";
           this.setState({
             serverData: data,
           });
      })
      .catch(error => {    
        console.log(error);              
      });
    } else {
      axios.get('https://hertta-uusi.wp.aucor.fi/wp-json/acf/v3/options/naytelaskuri/en/')
      .then(response => {
        let data = response.data.acf.en;     
        data.radio = ['Yes', 'No'];
        data.levels = ['Low', 'Medium', 'Increased', 'High'];
        data.heartTitle = "Cardiovascular Risk";
        data.diabTitle = "Diabetes Risk";
           this.setState({
             serverData: data
           });
      })
      .catch(error => {        
        console.log(error);
      });
    }
  }

  render(props) {
    return (
      <div className="App">
        {this.state && 
        <div id="appContainer">
          <header className="App-header">
            <Header 
              title={this.state.serverData.title} 
              subtitle={this.state.serverData.subtitle} 
            />
            <Form 
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit} 
              handleClick={this.handleClick}  
              serverData={this.state.serverData}
              selectedOption={this.state.selectedOption}
            /> 
          </header>
          <Results 
            ref={(Results) => { this.results = Results}}
            closed={this.state.closed}
            value={Math.round(this.state.valid.value * 100) / 100}
            valuediab={Math.round(this.state.valid.valuediab * 100) / 100}  
            serverData={this.state.serverData}
            selectedOption={this.state.valid.selectedOption}
          />
          <div className="footer"><img src={logo} alt="" /></div>
        </div>}
      </div>
    );
  }
}

export default App;
