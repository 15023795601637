import React, { Component } from "react";
import {NumberInput , RadioInput, NumberInputDiab} from  '../../components/inputs/inputs';
import "./form.scss";

class Form extends Component {

    render() {

      const inputData = {
        sepel: {
          name: "sepel",
          range_min: 0,
          range_max: 12,
          label: this.props.serverData && this.props.serverData.heart_input
        },
        diab: {
          name: "diab",
          range_min: 0,
          range_max: 12,
          label: this.props.serverData && this.props.serverData.diab_input
        }
      }
  
      const radioData = {
        sepel: {
          name: "selectedOption",
          label: this.props.serverData && this.props.serverData.sepel_input
        }
      }

      return (

      <form className="laskuriform" onSubmit={this.props.handleSubmit}>
        <div className="input-wrap">
          <NumberInput handleChange={this.props.handleChange} inputLabel={inputData.sepel.label} name={inputData.sepel.name} value={this.props.value} /> 
          <RadioInput 
            handleChange={this.props.handleChange} 
            inputLabel={radioData.sepel.label} 
            name={radioData.sepel.name}
            selectedOption={this.props.selectedOption}
            radio={this.props.serverData.radio}
             />
        </div>

        <div className="input-wrap">
          <NumberInputDiab handleChange={this.props.handleChange} inputLabel={inputData.diab.label} name={inputData.diab.name}/>
        </div>
        <div className="input-wrap-submit">
          <input 
            type="submit" 
            value={this.props.serverData.submit_input} 
            onClick={this.props.handleClick} 
            to="results"
            />
        </div>
      </form>
      );
    }
  }

  export default Form;