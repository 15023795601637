import React, { Component } from "react";
import './inputs.scss';

class NumberInput extends Component {
    componentDidMount() {
        this.setState({value: ""});
    }
    render() {
      return (
        <label>{this.props.inputLabel}
          <input 
            type="number"
            value={this.props.value} 
            name="value" 
            onChange={this.props.handleChange} 
            placeholder="0-12"
            min="0"
            max="12"
            step="any"
          />
        </label>
      );
    }
  }

class NumberInputDiab extends Component {
  componentDidMount() {
      this.setState({valuediab: ""});
  }
  render() {
    return (
      <label>{this.props.inputLabel}
        <input
          type="number"
          valuediab={this.props.valuediab}
          name="valuediab"
          onChange={this.props.handleChange}
          placeholder="0-100"
          min="0"
          max="100"
          step="any"
        />
      </label>
    );
  }
}

  
class RadioInput extends Component {
  render(props) {
    return (
      <div className="radiobutton">
        <label>{this.props.inputLabel}</label>
        <input className="radio" id="radio-1" name={this.props.name} type="radio" onChange={this.props.handleChange} value="1" checked={this.props.selectedOption === "1"} />
        <label htmlFor="radio-1" className="radio-label">{this.props.radio && this.props.radio[0]}</label>
        <br />
        <input className="radio" id="radio-2" name={this.props.name} type="radio" onChange={this.props.handleChange} value="0" checked={this.props.selectedOption === "0"} />
        <label htmlFor="radio-2" className="radio-label">{this.props.radio && this.props.radio[1]}</label>
      </div>
    );
  }
}

  export {NumberInput, NumberInputDiab,  RadioInput };