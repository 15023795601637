import React, { Component } from "react";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import ProgressBar from  '../ProgressBar/ProgressBar';
import ProgressBarDiab from  '../ProgressBar/ProgressBarDiab';
import ResultRow from './resultrow';

class Results extends Component {
    renderHeartText(value) {
        if(!this.props.serverData) {
            return ['', ''];
        }
        const { heart } = this.props.serverData;
        
        const { levels} = this.props.serverData;
        const option = this.props.selectedOption;
        if(!heart || !levels) {
            return ['', ''];
        }
        if( value > 9) {
            return [option<1 ? heart.text_4 : heart.text_4_sep, 'korkea', levels[3]];
        } else if ( value >= 7 ) {
            return [option<1 ? heart.text_3 : heart.text_3_sep, 'kohonnut', levels[2]];
        } else if ( value > 3 ) {
            return [option<1 ? heart.text_2 : heart.text_2_sep, 'keskitaso', levels[1]];
        } else {
            return [option<1 ? heart.text_1 : heart.text_1_sep, 'matala', levels[0]];
        }
    }
    renderDiabetesText(value) {
        if(!this.props.serverData) {
            return ['', ''];
        }
        const { diabetes } = this.props.serverData;
        const { levels} = this.props.serverData;
        if(!diabetes || !levels ) {
            return ['', ''];
        }
        if( value >= 15) {
            return [diabetes.text_3, 'korkea', levels[3]];
        }  else if ( value >= 5 ) {
            return [diabetes.text_2, 'keskitaso', levels[1]];
        } else {
            return [diabetes.text_1, 'matala', levels[0]];
        }
    }
    render(props) {
        return (
        <SlideDown className={'my-dropdown-slidedown'} closed={this.props.closed}>
 
            <div className="results" name="results">
                <ResultRow 
                    bartype={ProgressBar}
                    value={this.props.value}
                    valuetitle={this.renderHeartText(this.props.value)} 
                    title={this.props.serverData.heartTitle}
                    levels={this.props.serverData.levels}
                    average="3"
                    text={this.renderHeartText(this.props.value)} 
                /> 
                <div className="hr"></div>
                <ResultRow 
                    bartype={ProgressBarDiab}
                    value={this.props.valuediab}
                    percentage=" %" 
                    valuetitle={this.renderDiabetesText(this.props.valuediab)}
                    title={this.props.serverData.diabTitle}
                    levels={this.props.serverData.levels}
                    average="6"
                    text={this.renderDiabetesText(this.props.valuediab)} 
                />
            </div>
        </SlideDown>
        );
    }
}

export default Results;