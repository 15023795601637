import React, { Component } from "react";
import './progressbar.scss';
import logo from '../../images/Diabetes.svg';
import classnames from 'classnames';

class ProgressBarDiab extends Component {

    renderSteps(step) {
        const value = this.props.value;
        return(
            <div>{value}</div>
        )
    }
    
    render(props) {
        let width = 0; 
        let value = this.props.value;
        let third = 100/3;
        if (value <= 5) {
            width = (((third)/5) * value);  
        } else if (value > 5 && value <= 15) {
            width = (((third/10) * (value - 5)) + third);
        } else if (value > 15 && value <= 100) {
            width = ((third/85) * (value - 15)) + (third*2);
        }

        if (width > 100) {
            width = 100 + '%';
        } else {
            width = width + '%';
        }
        var borderRadius = "";
        if(value >= 97) {
            borderRadius = "20px 20px 20px 20px";
        } else {
            borderRadius = "20px 0 0 20px";
        }

        return (
            <div className="progress-bar-wrap">
                <div className="progress-bar-header">
                    <img className="logo" src={logo} alt="{this.props.title}" /><h2>{this.props.title}</h2>
                </div>
                <span className="digits">
                    <span>0</span>
                    <span className="five">5</span>
                    <span className="fifteen">15</span>
                    <span>100</span>
                </span>
                <div className="progress-bar diab">
                <div className={classnames('progress-bar-fill', this.props.valuetitle)} style={{
                    width: width, borderRadius: borderRadius}}></div>
                    <div className="stepline stepline__1"></div>
                    <div className="stepline stepline__2"></div>
                </div>
                <div className={classnames('steps', this.props.valuetitle)}>
                    <span className="matala">{this.props.level && this.props.levels[0]}</span>
                    <span className="keskitaso">{this.props.level && this.props.levels[1]}</span>
                    <span className="korkea">{this.props.level && this.props.levels[3]}</span>
                </div>
            </div>
        );
    }
}

export default ProgressBarDiab; 