import React, { Component } from "react";
import ResultDescription from  '../ResultDescription/ResultDescription';
import './results.scss';


class ResultRow extends Component {
    render() {
        return (
            <div className="results-row">
                <this.props.bartype 
                    value={this.props.value} 
                    title={this.props.title} 
                    valuetitle={this.props.text[1]}
                    level={this.props.text[2]}
                    levels={this.props.levels}
                />
            
                <ResultDescription 
                    value={this.props.value} 
                    percentage={this.props.percentage}
                    text={this.props.text[0]}
                    valuetitle={this.props.text[1]}
                    average={this.props.average}
                    level={this.props.text[2]}
                    levels={this.props.levels}
                /> 
            </div>
        );
    }
}

export default ResultRow;